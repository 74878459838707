import React from 'react'

export default function Shops() {
  return (
    <div className="layout-body">
        Shops
      
    </div>
  )
}
