import React from 'react'

export default function Sellers() {
  return (
    <div className="layout-body">
        Sellers
      
    </div>
  )
}
