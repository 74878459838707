import React from 'react'

export default function Employees() {
  return (
    <div className="layout-body">
      Employees

    </div>
  )
}
