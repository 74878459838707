import React from 'react';


export default function TopNav() {
    return (
        <div className='top-nav'>
            <div className="searc-wrapper">
                <input type="search" />
            </div>
            <div className="profile-wrapper">
                <p>Profile Name</p>
            </div>
        </div>
    )
}
