import React from 'react'

export default function Table() {
    return (
        <>
            <table className="table table-dark table-hover">
                <thead>
                    <tr>
                        <th>S No</th>
                        <th>Date</th>
                        <th>Inves</th>
                        <th>New Qty</th>
                        <th>Old Qty</th>
                        <th>Total Qty</th>
                        <th>Sal Qty</th>
                        <th>Sal Amt</th>
                        <th>Exp</th>
                        <th>Avg Pur</th>
                        <th>Avg Sale</th>
                        <th>Profit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                    </tr>
            
                </tbody>
            </table>
        </>
    )
}
